.breadcrumb {
  // scss-docs-start breadcrumb-css-vars
  --#{$prefix}breadcrumb-padding-x: #{$breadcrumb-padding-x};
  --#{$prefix}breadcrumb-padding-y: #{$breadcrumb-padding-y};
  --#{$prefix}breadcrumb-margin-bottom: #{$breadcrumb-margin-bottom};
  @include rfs($breadcrumb-font-size, --#{$prefix}breadcrumb-font-size);
  --#{$prefix}breadcrumb-bg: #{$breadcrumb-bg};
  --#{$prefix}breadcrumb-border-radius: #{$breadcrumb-border-radius};
  --#{$prefix}breadcrumb-divider-color: #{$breadcrumb-divider-color};
  --#{$prefix}breadcrumb-item-padding-x: #{$breadcrumb-item-padding-x};
  --#{$prefix}breadcrumb-item-active-color: #{$breadcrumb-active-color};
  // scss-docs-end breadcrumb-css-vars

  display: flex;
  flex-wrap: wrap;
  padding: var(--#{$prefix}breadcrumb-padding-y) var(--#{$prefix}breadcrumb-padding-x);
  margin-bottom: var(--#{$prefix}breadcrumb-margin-bottom);
  @include font-size(var(--#{$prefix}breadcrumb-font-size));
  list-style: none;
  background-color: var(--#{$prefix}breadcrumb-bg);
  @include border-radius(var(--#{$prefix}breadcrumb-border-radius));
}

$chevron-right: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23667085' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E";

.breadcrumb-item {
  + .breadcrumb-item {
    display: flex;
    align-items: center;
    padding-left: var(--#{$prefix}breadcrumb-item-padding-x);

    &::before {
      float: left;
      padding-right: var(--#{$prefix}breadcrumb-item-padding-x);
      background-image: url(#{$chevron-right});
      background-size: 16px;
      background-repeat: no-repeat;
      width: 16px;
      height: 16px;
      content: "";
    }
  }

  &.active {
    color: var(--#{$prefix}breadcrumb-item-active-color);
  }
}


