.input-manage-stock {
  border-top: 1px solid #ebecf0;
  padding-top: 15px;
  border-bottom: 1px solid #ebecf0;
}

.padding-horizontal {
  margin-left: 15px;
  margin-right: 15px;
}

.card-stock {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.card-content {
  padding: 20px;
}

.card-content span {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.pl-4 {
  padding-left: 20px;
}

.pl-2 {
  padding-left: 10px;
}

.pr-2 {
  padding-right: 10px;
}

.button-wrap {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.text-align-center {
  text-align: center;
}

.small-padding {
  padding: 10px;
}

.border-bottom {
  border-bottom: 1px black solid;
}

.regular-border-bottom {
  border-bottom: 3px #f7f7f7 solid;
}

.no-padding {
  padding: 0;
  margin: 0;
}

.regular-padding {
  padding: 15px;
}

.background-plus {
  background-color: #017949;
  border-radius: 14px;
  padding: 10px;
  margin-top: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  align-items: center;
  content: '';
}

.modal-bottom {
  background-color: red;
  position: fixed;
  bottom: -100%;
  padding-bottom: 20px;
  width: 100%;
}

.bordered {
  border: 1px #d0d5dd solid;
  border-radius: 8px;
}

.bordered-warning {
  border: 1px #f04438 solid;
  border-radius: 8px;
}

.border-input:focus {
  border-color: white !important;
  box-shadow: none !important;
}

.bordered-primary {
  border: 1px #006386 solid;
  border-radius: 8px;
}

.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 18px;
  background-color: #017949;
  color: #fff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
  cursor: pointer;
  font-size: 24px;
}

.scrollable-container {
  max-height: 70vh;
  overflow-y: auto;
}

.regular-scrollable-container {
  max-height: 60vh;
  overflow-y: auto;
}

.small-scrollable-container {
  max-height: 40vh;
  overflow-y: auto;
}

.empty-image {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 60vh;
}

.primary-bg-25 {
  background-color: #ecf9fe;
}

.regular-padding-horizontal {
  padding-left: 15px;
  padding-right: 15px;
}

.small-padding-bottom {
  padding-bottom: 10px;
}

.regular-padding-bottom {
  padding-bottom: 15px;
}

.regular-padding-top {
  padding-top: 15px;
}

.disabled {
  background-color: #e9ecef;
}

.small-padding-vertical {
  padding-bottom: 10px;
  padding-top: 10px;
}

.small-margin-right {
  margin-right: 10px;
}

.regular-margin-right {
  margin-right: 15px;
}

.action-menu {
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-right: 50px;
  padding-left: 10px;
  z-index: 1000;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  width: auto;
  max-width: 90%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
}

@media (min-width: 768px) {
  .action-menu {
    top: auto;
    left: auto;
    transform: none;
    margin-top: 250px;
    margin-left: -190px;
  }

  .overlay {
    display: none;
  }
}

.show-overlay {
  display: block;
}

.basic-card {
  margin-top: 10px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  padding: 10px;
}

.col-space-between {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
