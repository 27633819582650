.custom-sidebar-logs .modal-content {
  overflow-y: none !important;
}

.custom-sidebar-logs .timeline-container {
  overflow-y: auto;
  max-height: 80vh;
}

.timeline-container {
  position: relative;
}

.timeline-item {
  display: flex;
  align-items: flex-start;
  position: relative;
  padding-bottom: 1.5rem;
}

.timeline-icon {
  position: relative;
  margin-right: 1rem;
}

.circle-icon {
  width: 10px;
  height: 10px;
  background-color: #006386;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

.circle-icon-wrap {
  width: 20px;
  height: 20px;
  background-color: #b4d5e1;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
}

.timeline-item::before {
  content: '';
  position: absolute;
  width: 2px;
  top: 0;
  bottom: 0;
  left: 4px;
  border-left: 2px dashed #eaecf0;
  margin-left: 5px;
}

.timeline-item:last-child::before {
  border-left: none;
}

.timeline-content {
  display: flex;
  flex-direction: column;
}

.layout {
  display: flex;
}

.sidebar {
  width: 250px;
  transition: transform 0.3s ease-in-out;
}

.sidebar-open .main-content {
  margin-left: 250px;
  transition: margin-left 0.3s ease-in-out;
}

.main-content {
  flex-grow: 1;
  transition: margin-left 0.3s ease-in-out;
}

.chart-container {
  flex-grow: 1;
  width: 100%;
  background-color: red;
}

.no-border::before {
  border-left: none; /* Remove the dashed border */
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
