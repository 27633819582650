@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import 'mixins/banner';
@include bsBanner('');

// scss-docs-start import-stack
// Configuration
@import 'functions';
@import 'variables';
@import 'variables-dark';
@import 'maps';
@import 'mixins';
@import 'utilities';

// Layout & components
@import 'root';
@import 'reboot';
@import 'type';
@import 'images';
@import 'containers';
@import 'grid';
@import 'tables';
@import 'forms';
@import 'buttons';
@import 'transitions';
@import 'dropdown';
@import 'button-group';
@import 'nav';
@import 'navbar';
@import 'card';
@import 'accordion';
@import 'breadcrumb';
@import 'pagination';
@import 'badge';
@import 'alert';
@import 'progress';
@import 'list-group';
@import 'close';
@import 'toasts';
@import 'modal';
@import 'tooltip';
@import 'popover';
@import 'carousel';
@import 'spinners';
@import 'offcanvas';
@import 'placeholders';

// Helpers
@import 'helpers';

// Utilities
@import 'utilities/api';
// scss-docs-end import-stack

// Custom style
body {
  font-family: 'Inter', sans-serif;
  font-optical-sizing: auto;
  font-variation-settings: 'slnt' 0;
}

.flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-centered-start {
  display: flex;
  align-items: center;
}

.nav-link {
  font-size: $font-size-sm;

  &.active-link {
    color: $primary;
    text-decoration: underline solid;
    text-underline-offset: 16px;
    font-weight: $font-weight-semibold;
  }
}

.card-body {
  & header {
    padding: 0;
  }
}

.text-normal {
  font-size: 16px;
}

.text-sm {
  font-size: 14px;
}

.text-xs {
  font-size: 12px;
}

.text-md {
  font-size: 18px;
}

.badge-verify {
  padding: 0.5rem;
  color: rgba(3, 152, 85, 1) !important;
  background-color: rgba(236, 253, 243, 1) !important;
}

.badge-warning {
  padding: 0.5rem;
  color: rgba(181, 71, 8, 1) !important;
  background-color: rgba(255, 250, 235, 1) !important;
}

.badge-process {
  padding: 0.5rem;
  color: rgba(0, 83, 112, 1) !important;
  background-color: rgba(236, 250, 255, 1) !important;
}

.badge-cancel {
  padding: 0.5rem;
  color: #f87171 !important;
  background-color: #fef2f2 !important;
}

.badge-danger {
  padding: 0.5rem;
  color: rgba(180, 35, 24, 1) !important;
  background-color: #fef2f2 !important;
}

.badge-blues {
  padding: 0.5rem;
  color: #006386 !important;
  background-color: #ecfaff !important;
}

.badge-gray {
  padding: 0.5rem;
  color: #344054 !important;
  background-color: #f2f4f7 !important;
}

.img-page-forbidden {
  width: 100%;
  height: 100vh;
  object-position: bottom;
  object-fit: cover;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@include media-breakpoint-down(md) {
  .img-page-forbidden {
    height: calc(1440px / 3);
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.menu-categories-approval {
  padding: 0.5rem 0;

  &.active {
    background-color: #f2f4f7;
  }
}

.btn-dashboard-secondary.active {
  background-color: #f2f4f7;
}

.btn-dashboard-secondary:hover {
  background-color: #f2f4f7;
}

.border-plat {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.nav-active {
  text-decoration: none;
  color: black;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #f9fafb;
  padding: 8px;
  border-radius: 6px;
}

.nav-unactive {
  text-decoration: none;
  color: #667085;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 6px;
}
// Colors
$gray-color-50: #f9fafb;
$gray-color-100: #f2f4f7;
$gray-color-300: #d0d5dd;
$gray-color-500: #667085;
$gray-color-600: #475467;
$gray-color-700: #344054;

$warning-color-25: #fffcf5;
$warning-color-50: #fffaeb;
$warning-color-700: #b54708;

$success-color-25: #f6fef9;
$success-color-50: #ecfdf3;
$success-color-100: #d1fadf;
$success-color-200: #a6f4c5;
$success-color-300: #6ce9a6;
$success-color-400: #32d583;
$success-color-500: #12b76a;
$success-color-600: #039855;
$success-color-700: #027a48;
$success-color-800: #05603a;
$success-color-900: #054f31;

$error-color-50: #fef3f2;
$error-color-50: #fef3f2;
$error-color-100: #fee4e2;
$error-color-200: #fecdca;
$error-color-300: #fda29b;
$error-color-400: #f97066;
$error-color-500: #f04438;
$error-color-600: #d92d20;
$error-color-700: #b42318;
$error-color-800: #912018;
$error-color-900: #7a271a;

$primary-color-600: #006386;
$primary-color-50: #ecfaff;
$primary-color-25: #f3feff;

.text-gray-300 {
  color: $gray-color-300;
}
.text-gray-500 {
  color: $gray-color-500;
}
.text-gray-600 {
  color: $gray-color-600;
}
.text-gray-700 {
  color: $gray-color-700;
}
.text-primary-600 {
  color: $primary-color-600;
}

.btn-color-uang-usaha {
  border-color: $gray-color-300;
}

.bg-warning-50 {
  background-color: $warning-color-50;
}

.btn-cancel {
  border-color: $gray-color-300;
  color: $gray-color-700;
  &:hover {
    color: $gray-color-600;
    background-color: $gray-color-100;
  }
  &:disabled {
    color: $gray-color-600 !important;
    background-color: $gray-color-300 !important;
  }
}

.text-gray-600 {
  color: $gray-color-600;
}
.text-gray-700 {
  color: $gray-color-700;
}
.text-warning-700 {
  color: $warning-color-700;
}
.text-success-700 {
  color: $success-color-700;
}
.text-danger-700 {
  color: $error-color-700;
}

.btn-color-uang-usaha {
  border-color: $gray-color-300;
}

.bg-warning-25 {
  background-color: $warning-color-25;
}

.bg-warning-50 {
  background-color: $warning-color-50;
}

.bg-success-25 {
  background-color: $success-color-25;
}

.bg-success-50 {
  background-color: $success-color-50;
}

.bg-primary-50 {
  background-color: $primary-color-50;
}

.bg-primary-25 {
  background-color: $primary-color-25;
}

.bg-gray-100 {
  background-color: $gray-color-100;
}

.bg-danger-50 {
  background-color: $error-color-50;
}
.bg-gray-50 {
  background-color: $gray-color-50;
}

.btn-cancel {
  border-color: $gray-color-300;
  color: $gray-color-700;
  &:hover {
    color: $gray-color-600;
    background-color: $gray-color-100;
  }
}

.notif-item :hover {
  background-color: #f9fafb;
}

// Auto Repo Badge
.badge-repo-pending {
  background-color: $warning-color-50;
  color: $warning-color-700;
  padding: 2px 8px;
}

.badge-repo-success {
  background-color: $success-color-50;
  color: $success-color-700;
  padding: 2px 8px;
}

.badge-repo-danger {
  background-color: $error-color-50;
  color: $error-color-700;
  padding: 2px 8px;
}

//font weight
.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

//pointer
.pointer {
  cursor: pointer;
}

.hover-100 {
}

.hover-100:hover {
  background-color: #f2f4f7;
}
//hover
.hover {
}

.hover:hover {
  background-color: #f9fafb;
}

.button-no-focus {
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  color: $primary-color-600;
}

.button-no-focus:focus,
.button-no-focus:active,
.button-no-focus:hover {
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  color: $primary-color-600;
  outline-offset: none !important;
}
