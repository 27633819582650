.modal-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    line-height: 1.5;
}

/* .imageModalBody {
    margin:auto;
    width: fit-content;
    max-height: 825px;
    min-height: 250px;
} */
