.button-delete {
  background-color: #d92d20 !important;
  border-color: #f97066 !important;
  color: white !important;
}

.button-delete:hover {
  background-color: #b8261a !important;
  border-color: #d92d20 !important;
  color: white !important;
}

.button-cancel {
  background-color: #ffffff !important;
  border-color: #c1bfbf !important;
  color: #0b0a0a !important;
}

.button-cancel:hover {
  background-color: #f9fafb !important;
  border-color: #c1bfbf !important;
  color: #0b0a0a !important;
}
