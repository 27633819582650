.my-hover-card {
  display: flex;
  padding: 10px;
  border-radius: 7px;
  border-color: #d0d5dd;
  border-width: 1px;
  border-style: solid;
  transition: box-shadow 0.3s ease;
  cursor: pointer;
  align-items: center;
}

.my-hover-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.my-hover-card:active,
.my-hover-card:focus {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.button-budget {
  flex: 0.49;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.button-budget.white {
  background-color: white;
  border-color: #d0d5dd;
  color: black;
}

.button-budget.white:hover {
  background-color: #006386;
  color: white;
}

.button-budget.color {
  background-color: #006386;
  border-color: #006386;
  color: white;
}

.button-budget.color:hover {
  background-color: white;
  color: #006386;
  border-color: #006386;
}

.top-section {
  flex-shrink: 0;
  padding: 16px;
  background-color: #f0f0f0;
}

.scrollable-section {
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px;
}

.transactionWrap {
  padding-bottom: 15px;
  border-bottom: 1px solid #eaecf0;
  margin-bottom: 15px;
}

.dropdown-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.responsive-height {
  max-height: 53vh;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.responsive-height::-webkit-scrollbar {
  display: none;
}

@media (max-width: 768px) {
  .responsive-height {
    max-height: 51vh;
  }
}

.card-custom {
  background: linear-gradient(92.01deg, #14779a 0%, #006386 100%);
  color: white;
  height: 10vh;
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
