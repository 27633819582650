.sidebar {
  width: 250px;
  height: 100vh;
  padding: 15px;
  border-right: 5px solid lightgray;
}

.farmer-card {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px rgb(214, 214, 214) solid;
}

.title-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  cursor: pointer;
}

.container-refresh {
  position: relative;
}

.refresh-button {
  position: fixed;
  top: 150px;
  right: 10px;
  padding: 10px;
  background-color: white;
  border: 1px solid #ccc;
  cursor: pointer;
  z-index: 1000;
  border-radius: 99px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.menu-button {
  position: fixed;
  top: 210px;
  right: 10px;
  padding: 10px;
  background-color: white;
  border: 1px solid #ccc;
  cursor: pointer;
  z-index: 1000;
  border-radius: 99px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ml-small {
  margin-left: 10px;
}
